input:invalid { 
    border: 1px solid red;
    border-radius: 5px;
  }  
  input:required {
    border-color: red !important; 
  }

 option:invalid {
    border-color: red !important; 
}
 
nav li{
    display: inline;
    margin: 0 0 0 10%;
    
}
nav a{
    color: white;
    font-family: Roboto;
    font-size: 22px;
    text-decoration: none;
    font-weight:300;
    
}
nav{
    padding-top: 25px;
    width: 100%;

    

}

.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    margin-top: 0px !important;
}


.form_submitted  input {
    border-color: green !important;
 }

  @media only screen and (max-width: 500px) {
    nav li {
        display:block;
        margin: 0 0 0 0;
    }
  }


.circular-progress {
    border: 1px solid transparent;
    border-top: 1px solid #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    box-sizing: border-box;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.54);
    color: #54b5cf !important;
}