.checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #a9a9a9;
  cursor: pointer;
  flex-wrap: wrap;
}

.checkbox-label span {
  margin-left: 32px;
}

.checkbox-label input[type='checkbox'] {
  cursor: pointer;
  appearance: none;
  position: absolute;

  box-sizing: border-box;

  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #AEAEB2;
  border-radius: 50%;
}

.checkbox-label input[type='checkbox']:not(:checked):hover {
  border: 2px solid #7a7f85;
}

.checkbox-label input[type='checkbox']:checked {
  background: url('../../assets/icons/checkbox-checked.svg') no-repeat center;
  background-size: 20px;
  border: 0;
}

.checkbox-label input[type='checkbox']:checked ~ span {
  color: #262d3c;
}