html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.pdf-container {
    width: 100%;
    height: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  @media print {
    .print-hidden {
      display: none; 
    }
    
  }